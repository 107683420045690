<template>
  <div v-if="displays.length === 1">
    <!-- Single Display -->
    <h4 class="section-heading m-b-md">
      {{ site.name }} ({{ displays.length }})
    </h4>
    <display :site="site" :client_id="client_id" :displays="displays" />
  </div>
  <div v-else>
    <h4 class="section-heading m-b-md">
      {{ site.name }} ({{ displays.length }})
    </h4>
  </div>
</template>

<script>
import Display from "./Display.vue";

export default {
  name: "Displays",
  props: {
    site: Object,
    client_id: Number | String,
    displays: Array,
  },
  components: { Display },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style></style>
