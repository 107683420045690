<template>
  <div class="row">
    <div style="padding: 20px 12px 0">
      <span class="site-name"> {{ name }} </span>
      <button
        class="btn btn-primary"
        style="float: right"
        @click="() => addFoodItemModal.show()"
      >
        {{ $t("sites.temperature.add_food_item") }}
      </button>
    </div>

    <ul class="nav nav-pills nav-justified m-t-sm" role="tablist" style="padding: 0 12px">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.dailyFood }"
          role="tab"
          :aria-selected="tabs.dailyFood"
          href="javascript:void(0);"
          @click="selectTab('dailyFood')"
        >
          {{ $t("sites.temperature.daily_food") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.hotFood }"
          role="tab"
          :aria-selected="tabs.hotFood"
          href="javascript:void(0);"
          @click="selectTab('hotFood')"
        >
          {{ $t("sites.temperature.hot_food") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.thawServe }"
          role="tab"
          :aria-selected="tabs.thawServe"
          href="javascript:void(0);"
          @click="selectTab('thawServe')"
        >
          {{ $t("sites.temperature.thaw_serve") }}
        </button>
      </li>
    </ul>

    <div
      class="tab-pane fade"
      v-show="tabs.dailyFood"
      :class="{ 'show active': tabs.dailyFood }"
      role="tabpanel"
    >
      <daily-food
        :site_id="site_id"
        :name="name"
        :newAddedItem="newAddedItem"
        ref="dailyFood"
        @dailyFoodCount="getCount"
      ></daily-food>
    </div>

    <div
      class="tab-pane fade"
      v-show="tabs.hotFood"
      :class="{ 'show active': tabs.hotFood }"
      role="tabpanel"
    >
      <hot-food
        :site_id="site_id"
        :name="name"
        :newAddedItem="newAddedItem"
        ref="hotFood"
      ></hot-food>
    </div>

    <div
      class="tab-pane fade"
      v-show="tabs.thawServe"
      :class="{ 'show active': tabs.thawServe }"
      role="tabpanel"
    >
      <thaw-serve
        :site_id="site_id"
        :name="name"
        :newAddedItem="newAddedItem"
        ref="thawServe"
      ></thaw-serve>
    </div>
  </div>

  <div
    class="modal fade"
    id="addFoodItemModal"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 class="m-r-sm textAlign-center flex">
            <span class="widget-list-item-icon">
              <i
                class="material-icons-outlined"
                style="vertical-align: middle; padding: 10px"
              >
                bakery_dining
              </i>
            </span>
            {{ $t("sites.temperature.add_food_item").toUpperCase() }}
          </h4>
          <div class="form-group p-v-sm" style="width: 100%">
            <label class="form-label"> Name </label>
            <input
              type="text"
              v-model="newItem.name"
              class="form-control"
              placeholder="Enter Name"
            />
          </div>
          <div class="form-group">
            <label for="foodType" class="form-label"> Type </label>
            <select id="foodType" class="form-select" v-model="newItem.type">
              <option value="" selected disabled>
                {{ $t("sites.temperature.select_food_type") }}
              </option>
              <option value="daily-food">
                {{ $t("sites.temperature.daily_food") }}
              </option>
              <option value="hot-food">
                {{ $t("sites.temperature.hot_food") }}
              </option>
              <option value="thaw-serve">
                {{ $t("sites.temperature.thaw_serve") }}
              </option>
            </select>
          </div>
          <div class="flex-center p-v-sm">
            <button
              class="btn btn-secondary m-r-sm"
              @click="() => addFoodItemModal.hide()"
            >
              {{ $t("global.close") }}
            </button>
            <button class="btn btn-primary" @click="addFoodItem">
              <span
                v-show="addingFoodItem"
                class="spinner-border spinner-border-sm m-r-xxs"
              ></span>
              <span>
                <i class="material-icons"> add </i> {{ $t("global.add") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import temperatureService from "../../../../../services/temperature.service";
import DailyFood from "./foods/dailyfood/DailyFood.vue";
import HotFood from "./foods/hotfood/HotFood.vue";
import ThawServe from "./foods/thawserve/ThawServe.vue";

export default {
  name: "Beverages",
  props: {
    site_id: Number,
    name: String,
  },
  components: {
    DailyFood,
    HotFood,
    ThawServe,
  },
  mounted() {
    this.addFoodItemModal = new bootstrap.Modal(
      document.getElementById("addFoodItemModal")
    );
  },
  data() {
    return {
      tabs: {
        dailyFood: true,
        hotFood: false,
        thawServe: false,
      },
      addFoodItemModal: null,
      newItem: {
        name: "",
        type: "",
      },
      addingFoodItem: false,
    };
  },
  methods: {
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
      if (tab === "dailyFood") {
        this.newItem.type = "daily-food";
      } else if (tab === "hotFood") {
        this.newItem.type = "hot-food";
      } else if (tab === "thawServe") {
        this.newItem.type = "thaw-serve";
      } else {
        console.error("Unknown tab:", tab);
      }
    },
    addFoodItem() {
      this.addingFoodItem = true;
      temperatureService
        .addFoodItem(
          this.$route.params.id,
          this.newItem.name,
          this.newItem.type
        )
        .then((response) => {
          console.log(response.data);
          this.$success(this.$t("sites.temperature.add_food_item_success"));
          this.addingFoodItem = false;
          //update table
          if (this.newItem.type === "daily-food") {
            this.$refs.dailyFood.getProductList();
          } else if (this.newItem.type === "hot-food") {
            this.$refs.hotFood.getProductList();
          } else if (this.newItem.type === "thaw-serve") {
            this.$refs.thawServe.getProductList();
          }
          this.newItem.name = "";
          this.newItem.type = "";
          this.addFoodItemModal.hide();
          this.addingFoodItem = false;
        })
        .catch((error) => {
          this.$error(this.$t("sites.temperature.add_food_item_failed"), error);
          this.addingFoodItem = false;
        });
    },
  },
};
</script>

<style scoped>
.site-name {
  font-size: 25px;
}

.nav-tabs {
  border-bottom: none;
}

.widget-list-item-icon {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  margin-right: 10px;
  vertical-align: middle;
  float: left;
  color: #fd7e14;
  background: rgba(255, 149, 0, 0.25) !important;
}

.flex {
  align-items: center;
}

.m-r-sm.textAlign-center.flex {
  width: fit-content;
  margin: auto;
}
</style>
