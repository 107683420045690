<template>
    <div>
        <div v-if="!fridgeSelected" class="p-v-sm">
            <table class="table table-borderless"> 
                <thead>
                    <tr>
                        <th> {{$t('sites.name').toUpperCase()}} </th>
                        <th> DATE/TIME CREATED </th>
                        <th>  </th>
                    </tr>
                </thead>
    
                <tbody>
                    <tr v-if="list.length < 1">
                        <td colspan="3" class="no-data-container">{{$t('global.no_data')}}</td>
                    </tr>
                    <tr v-for="elem of list" :key="elem.index">
                        <td class="cur_pointer" @click="showFridgeTemp(elem)"> {{elem.name}} </td>
                        <td class="cur_pointer" @click="showFridgeTemp(elem)"> {{elem.datetime}} </td>
                        <td> <button class="btn btn-sm btn-danger" @click="deactivate(elem)">Deactivate</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    
        <active-fridge-temperature v-if="fridgeSelected" :fridge="selectedFridge" @hideFridgeTemperature="() => {fridgeSelected = !fridgeSelected}"></active-fridge-temperature>
    </div>
</template>

<script>
import ActiveFridgeTemperature from './ActiveFridgeTemperature.vue';

export default {
    name: 'ActiveFridges',
    props: {
        list: Array
    },
    components: {
        ActiveFridgeTemperature
    },
    data() {
        return {
            selectedFridge: {},
            fridgeSelected: false
        }
    },
    methods: {
        showFridgeTemp(fridge) {
            this.selectedFridge = fridge;
            this.fridgeSelected = true;   
        },
        deactivate(fridge){
            this.$emit('fridge', fridge)
        }
    }
}
</script>

<style>

</style>