import axios from "axios";
import authHeader from "./auth-header";
import { DateTime } from "luxon";

const API_URL = `${process.env.VUE_APP_API_URL}temperature`;

class TemperatureService {
  getFridges(site_id) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/unit/fridge?site_id=${site_id}&count=2000`,
      { headers: authHeader() }
    );
    // return axios.get(`${API_URL}/unit?site_id=${site_id}`, {params: {type: 'fridge'}}, {headers: authHeader()});
  }

  addNewFridge(site_id, fridge_name) {
    return axios.put(
      `https://api.varsanpr.com/api/temperature/unit`,
      {
        site_id: site_id,
        name: fridge_name,
        type: "fridge",
      },
      { headers: authHeader() }
    );
  }

  // Calibration

  getCalibrationDevices(site_id) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/unit/calibration?site_id=${site_id}&count=2000`,
      { headers: authHeader() }
    );
    // return axios.get(`${API_URL}/unit?site_id=${site_id}`, {params: {type: 'fridge'}}, {headers: authHeader()});
  }

  addNewCalibrationDevice(site_id, device_name, serialNumber) {
    return axios.put(
      `https://api.varsanpr.com/api/temperature/unit`,
      {
        site_id: site_id,
        name: device_name,
        type: "calibration",
        serial_number: serialNumber,
      },
      { headers: authHeader() }
    );
  }

  //No data

  getDailyProducts(site_id, type, page = 1) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/product/${type}?site_id=${site_id}&page=${page}`,
      { headers: authHeader() }
    );
  }

  //No data

  getHotProducts(site_id, type) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/product/${type}?site_id=${site_id}`,
      { headers: authHeader() }
    );
  }

  addFoodItem(site_id, name, type) {
    return axios.put(
      `https://api.varsanpr.com/api/temperature/product`,
      {
        site_id: site_id,
        name: name,
        type: type,
      },
      { headers: authHeader() }
    );
  }

  activateCalibrationDevice(site_id, fridge) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/unit/${fridge}`,
      {
        site_id: site_id,
        status: true,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deactivateCalibrationDevice(site_id, fridge) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/unit/${fridge}`,
      {
        site_id: site_id,
        status: false,
      },
      {
        headers: authHeader(),
      }
    );
  }

  // End Calibration

  // Thaw-serve

  getThawServe(site_id) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/product/thaw-serve?site_id=${site_id}&count=2000`,
      { headers: authHeader() }
    );
  }

  addFoodItem(site_id, name, type) {
    return axios.put(
      `https://api.varsanpr.com/api/temperature/product`,
      {
        site_id: site_id,
        name: name,
        type: type,
      },
      { headers: authHeader() }
    );
  }

  getHotFoodTemperature(site_id, id, start_date, end_date, page) {
    let url = `https://api.varsanpr.com/api/temperature/product/${id}/record?site_id=${site_id}&page=${page}`;
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }
    return axios.get(url, { headers: authHeader() });
  }

  //End Thaw-serve


  getDailyProducts(site_id, type, page = 1) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/product/${type}?site_id=${site_id}&page=${page}&count=20000`,
      { headers: authHeader() }
    );
  }

  getDateCheck(site_id, id, start_date, end_date, page) {
    let url = `https://api.varsanpr.com/api/temperature/product/${id}/date?site_id=${site_id}&page=${page}`;
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }
    return axios.get(url, { headers: authHeader() });
  }

  getFoodTemperature(site_id, id, start_date, end_date, page) {
    let url = `https://api.varsanpr.com/api/temperature/product/${id}/record?site_id=${site_id}&page=${page}`;
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }
    return axios.get(url, { headers: authHeader() });
  }

  getHotProducts(site_id, type) {
    return axios.get(
      `https://api.varsanpr.com/api/temperature/product/${type}?site_id=${site_id}`,
      { headers: authHeader() }
    );
  }

  getHotFoodTemperature(site_id, id, start_date, end_date, page) {
    let url = `https://api.varsanpr.com/api/temperature/product/${id}/record?site_id=${site_id}&page=${page}`;
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }
    return axios.get(url, { headers: authHeader() });
  }

  getFridgeTemperature(site_id, id, start_date, end_date, page) {
    let url = `https://api.varsanpr.com/api/temperature/unit/${id}/record?site_id=${site_id}&page=${page}`;
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }
    return axios.get(url, { headers: authHeader() });
  }

  addFoodItem(site_id, name, type) {
    return axios.put(
      `https://api.varsanpr.com/api/temperature/product`,
      {
        site_id: site_id,
        name: name,
        type: type,
      },
      { headers: authHeader() }
    );
  }

  activateFridge(site_id, fridge) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/unit/${fridge}`,
      {
        site_id: site_id,
        status: true,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deactivateFridge(site_id, fridge) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/unit/${fridge}`,
      {
        site_id: site_id,
        status: false,
      },
      {
        headers: authHeader(),
      }
    );
  }

  activateDailyFood(site_id, food) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/product/${food}`,
      {
        site_id: site_id,
        status: true,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deactivateDailyFood(site_id, food) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/product/${food}`,
      {
        site_id: site_id,
        status: false,
      },
      {
        headers: authHeader(),
      }
    );
  }

  activateHotFood(site_id, food) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/product/${food}`,
      {
        site_id: site_id,
        status: true,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deactivateHotFood(site_id, food) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/product/${food}`,
      {
        site_id: site_id,
        status: false,
      },
      {
        headers: authHeader(),
      }
    );
  }

  activateThawServe(site_id, food) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/product/${food}`,
      {
        site_id: site_id,
        status: true,
      },
      {
        headers: authHeader(),
      }
    );
  }

  deactivateThawServe(site_id, food) {
    return axios.patch(
      `https://api.varsanpr.com/api/temperature/product/${food}`,
      {
        site_id: site_id,
        status: false,
      },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new TemperatureService();
