<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">
      <h1 class="table-heading">
        {{ $t("Daily Tasks") }}
      </h1>

      <div class="m-t-sm" style="justify-content: space-between; display: flex">
        <button class="btn btn-primary" @click="goBack">
          <i style="margin-left: 0px; margin-right: 0px" class="material-icons"
            >west</i
          >
        </button>
        <h3>{{ date }}</h3>
        <button class="btn btn-primary" @click="goForward">
          <i style="margin-left: 0px; margin-right: 0px" class="material-icons"
            >east</i
          >
        </button>
      </div>

      <div class="m-t-sm">
        <button
          class="btn"
          :class="showQuery ? 'btn-danger' : 'btn-primary'"
          @click="toggleInsert"
        >
          {{ showQuery ? "Cancel" : "Insert Task" }}
        </button>
        <button
          class="btn text-white float-right tx-uppercase tx-bold"
          @click="generateReport()"
          style="background-color: #1d6f42"
        >
          Export Excel
        </button>
      </div>

      <div class="card m-t-sm" v-show="showQuery">
        <div class="card-body">
          <table class="table">
            <thead>
              <th>{{ "Task" }}</th>
            </thead>
            <tbody>
              <td>
                <textarea
                  class="form-control"
                  placeholder="Enter Task Name"
                  v-model="newTask"
                ></textarea>
              </td>
            </tbody>
            <div class="message-box" v-show="showAddMessage">Task Added</div>
            <button
              class="btn btn-primary m-t-sm"
              @click="putTask(newTask)"
              :disabled="newTask === ''"
            >
              Add
            </button>
          </table>
        </div>
      </div>

      <table class="table table-striped m-t-lg m-b-lg" id="daily-tasks">
        <thead>
          <tr>
            <th scope="col" style="width: 35%">TASK</th>
            <th scope="col" style="width: 25%">STATUS</th>
            <th scope="col" style="width: 15%">EDIT</th>
            <th scope="col" style="width: 15%">REMOVE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td colspan="4">
              <div class="text-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>

          <!-- Table Content -->
          <tr
            v-if="!loading"
            v-for="task in dailyTasks"
            :key="'task-' + task.id"
            style="vertical-align: middle"
          >
            <td>
              <!-- Inline editing logic -->
              <div
                v-if="editedTaskId === task.id"
                class="d-flex align-items-center"
              >
                <input
                  type="text"
                  v-model="task.name"
                  class="form-control"
                  style="width: 70%"
                />
                <button class="btn btn-success m-l-sm" @click="patchTask(task)">
                  Save
                </button>
                <button class="btn btn-danger m-l-sm" @click="cancelEdit">
                  Cancel
                </button>
              </div>
              <div v-else>
                {{ task.name }}
              </div>
            </td>
            <td>
              <span v-if="task.done" class="text-success">Complete</span>
              <span v-else class="text-danger">Incomplete</span>
            </td>
            <td>
              <button class="btn btn-primary" @click="enableEdit(task.id)">
                Edit
              </button>
            </td>
            <td>
              <button class="btn btn-danger" @click="deleteTask(task.id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import siteService from "../../../../services/site.service";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";
import saveAs from "file-saver";

export default {
  name: "DailyTasks",
  props: {
    site: Object,
  },
  data() {
    return {
      dailyTasks: [],
      loading: false,
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0,
      },
      date: DateTime.local().toFormat("yyyy-MM-dd"),
      showQuery: false,
      showAddMessage: false,
      newTask: "",
      editedTaskId: null,
    };
  },
  mounted() {
    this.loadDailyTasks();
  },
  methods: {
    async loadDailyTasks(page = 1, params = {}) {
      this.loading = true;

      try {
        const response = await siteService.getDailyTasks(this.site.id, page);
        this.dailyTasks = response.data.data;
        this.pagination = response.data.pagination;

        const checkTasks = this.dailyTasks.map(async (task) => {
          try {
            const checkResponse = await siteService.getDailyTaskChecks(
              this.site.id,
              task.id,
              this.date
            );
            task.done = checkResponse.data.data.length > 0;
          } catch (error) {
            console.log(`Error fetching checks for task ${task.id}: `, error);
            task.done = false;
          }
        });

        await Promise.all(checkTasks);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    putTask(newTask) {
      if (newTask === "") {
        this.$error("Task field is empty");
        return;
      }

      this.loading = true;
      siteService
        .addNewDailyTask(this.site.id, newTask)
        .then((response) => {
          console.log(response.data);

          this.showAddMessage = true;
          setTimeout(() => {
            this.showAddMessage = false;
          }, 30000);
        })
        .catch((error) => {
          this.$error("Failed to add task", error);
        })
        .finally(() => {
          this.newTask = "";
          this.showQuery = !this.showQuery;
          this.loadDailyTasks();
          this.loading = false;
        });
    },
    patchTask(task) {
      this.loading = true;
      siteService
        .updateDailyTask(this.site.id, task.id, task.name)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          this.$error("Failed to update task", error);
        })
        .finally(() => {
          this.editedTaskId = null;
          this.loadDailyTasks();
          this.loading = false;
        });
    },
    enableEdit(taskId) {
      this.editedTaskId = taskId;
    },
    cancelEdit() {
      this.editedTaskId = null;
    },
    deleteTask(task_id) {
      if (confirm("Are you sure you want to delete this task?")) {
        this.loading = true;

        siteService
          .removeDailyTask(this.site.id, task_id)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            this.$error("Failed to delete task", error);
          })
          .finally(() => {
            this.loadDailyTasks();
            this.loading = false;
          });
      }
    },
    goBack() {
      this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd")
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd");
      this.loadDailyTasks();
    },
    goForward() {
      this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd")
        .plus({ days: 1 })
        .toFormat("yyyy-MM-dd");
      this.loadDailyTasks();
    },
    toggleInsert() {
      this.showQuery = !this.showQuery;
    },
    generateReport() {
      const reportData = this.dailyTasks.map((task) => {
        return {
          "Task Name": task.name,
          "Date of Tasks": this.date,
          Complete: task.done ? "Complete" : "Incomplete",
        };
      });
      const ws = XLSX.utils.json_to_sheet(reportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Daily Tasks");
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      saveAs(
        new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
        "daily-tasks.xlsx"
      );
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
  },
};
</script>
