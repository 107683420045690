import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class WhitelistService {
  getSiteWhitelist(page, site, { registration = null }) {
    const params = new URLSearchParams({
      page: page || 1,
    });
    if (registration !== null) params.append("registration", registration);
    return axios.get(
      `https://api.varsanpr.com/api/sites/${site}/whitelist?` +
        params.toString(),
      { headers: authHeader() }
    );
  }

  removeSiteWhitelist(site, registration) {
    return axios.delete(
      `https://api.varsanpr.com/api/sites/${site}/whitelist`,
      { headers: authHeader(), data: { registration: registration } }
    );
  }

  putSiteWhitelist(site, plates) {
    plates.forEach((plate) => {
      if (plate.expiry === null) delete plate.expiry;
    });
    return axios.put(
      `https://api.varsanpr.com/api/sites/${site}/whitelist`,
      {
        plates: plates,
        site_id: site,
      },
      { headers: authHeader() }
    );
  }

  getClientWhitelist(page, client, { registration = null }) {
    const params = new URLSearchParams({
      page: page || 1,
      client_id: client,
    });
    if (registration !== null) params.append("registration", registration);
    return axios.get(`${API_URL}whitelist/client?` + params.toString(), {
      headers: authHeader(),
    });
  }

  removeClientWhitelist(client, registration) {
    return axios.delete(`${API_URL}whitelist/client/`, {
      headers: authHeader(),
      data: { client_id: client, registration: registration },
    });
  }

  getGlobalWhitelist(page, { registration = null }) {
    const params = new URLSearchParams({
      page: page || 1,
    });
    if (registration !== null) params.append("registration", registration);
    return axios.get(`${API_URL}whitelist/global?` + params.toString(), {
      headers: authHeader(),
    });
  }

  removeGlobalWhitelist(registration) {
    return axios.delete(`${API_URL}whitelist/global/`, {
      headers: authHeader(),
      data: { registration: registration },
    });
  }
}

export default new WhitelistService();
