<template>
  <div class="row">
    <!-- Search Barrier Whitelist -->
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Search Whitelist</h5>
        </div>
        <div class="card-body">
          <p class="text-muted">
            Fields marked <span class="text-danger">*</span> are required.
          </p>

          <div class="form-group">
            <label for="registration" class="form-label"
              >Registration <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="registration"
              class="form-control"
              id="registration"
              placeholder="Enter registration"
            />
          </div>

          <div class="form-group">
            <label for="notes" class="form-label"
              >Notes <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="notes"
              class="form-control"
              id="notes"
              placeholder="Enter notes"
            />
          </div>

          <button
            class="btn btn-sm btn-primary float-end m-t-sm"
            :disabled="loadingWhitelist"
            @click="getWhitelist(1, true)"
          >
            <div v-if="loadingWhitelist" class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
            Search
          </button>
        </div>
      </div>
    </div>

    <!-- Add new entry -->
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Add Entry</h5>
        </div>
        <div class="card-body">
          <p class="text-muted">
            Fields marked <span class="text-danger">*</span> are required.
          </p>

          <div class="form-group">
            <label for="addRegistration" class="form-label"
              >Registration <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="entry.registration"
              class="form-control"
              id="addRegistration"
              placeholder="Enter registration"
            />
          </div>

          <div class="form-group m-t-sm">
            <label for="notes" class="form-label">Notes</label>
            <textarea
              class="form-control"
              v-model="entry.notes"
              id="notes"
              placeholder="Enter notes"
            ></textarea>
          </div>

          <div class="form-group m-t-sm">
            <label for="expiry" class="form-label">Expiry</label>
            <input
              type="date"
              class="form-control"
              v-model="entry.expiry"
              id="expiry"
              placeholder="Enter expiry"
            />
          </div>

          <button
            :disabled="adding"
            class="btn btn-sm btn-primary float-end m-t-sm"
            @click="putWhitelist"
          >
            <div v-if="adding" class="spinner-border" role="status">
              <span class="sr-only"></span>
            </div>
            Add Entry
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-t-sm">
    <div class="col-12">
      <Pagination :config="pagination" @search="getWhitelist"></Pagination>

      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Registration</th>
            <th>Notes</th>
            <th>Date Added</th>
            <th>Added By</th>
            <th>Expiry</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="whitelist.length < 1 && !loadingWhitelist">
            <td colspan="7">No entries...</td>
          </tr>
          <tr v-if="loadingWhitelist">
            <td colspan="7">
              <div class="text-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-if="!loadingWhitelist"
            v-for="entry in whitelist"
            :key="entry.id"
          >
            <td>{{ entry.id }}</td>
            <td>{{ entry.registration }}</td>
            <td>{{ entry.notes }}</td>
            <td>{{ entry.date_added }}</td>
            <td>{{ entry.added_by }}</td>
            <td>{{ entry.date_expires }}</td>
            <td>
              <button
                class="btn btn-sm btn-danger"
                @click="deleteWhitelist(entry)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination :config="pagination" @search="getWhitelist"></Pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../../../../components/Pagination.vue";
import authHeader from "../../../../services/auth-header";
import { DateTime } from "luxon";

export default {
  name: "Barrier",
  components: { Pagination },
  props: {
    site: Object,
    barrier_id: Number | String,
  },
  data() {
    return {
      barrier: null,
      loading: true,
      whitelist: [],
      registration: "",
      notes: "",
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0,
      },
      entry: {
        registration: "",
        notes: "",
        expiry: null,
      },
      adding: false,
      loadingWhitelist: false,
    };
  },
  mounted() {
    this.loadBarrier();
  },
  methods: {
    loadBarrier() {
      this.loading = true;
      axios
        .get(
          `https://api.varsanpr.com/api/sites/${this.site.id}/barriers/${this.barrier_id}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          this.barrier = res.data.barrier;
          this.loading = false;
          this.getWhitelist();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWhitelist(page = 1) {
      this.loadingWhitelist = true;
      let params = {};
      params.page = page;
      if (this.registration.length > 0) {
        params.registration = this.registration;
      }
      if (this.notes.length > 0) {
        params.notes = this.notes;
      }
      let query = new URLSearchParams(params).toString();
      axios
        .get(
          `https://api.varsanpr.com/api/sites/${this.site.id}/barriers/${this.barrier_id}/whitelist?${query}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          res.data.whitelist.forEach((entry) => {
            entry.date_added = DateTime.fromSeconds(entry.date_added).toFormat(
              "dd/MM/yyyy"
            );
            if (entry.date_expires != null) {
              entry.date_expires = DateTime.fromSeconds(
                entry.date_expires
              ).toFormat("dd/MM/yyyy");
            }
          });
          this.whitelist = res.data.whitelist;
          this.pagination = res.data.pagination;
          this.loadingWhitelist = false;
        })
        .catch((err) => {
          if (err.response) {
            this.$error(err.response.data.message, err);
          } else {
            this.$error(err.message, err);
          }
        })
        .finally(() => {
          this.loadingWhitelist = false;
        });
    },
    putWhitelist() {
      let data = {
        registration: this.entry.registration,
      };
      if (this.entry.notes.length > 0) {
        data.notes = this.entry.notes;
      }
      if (this.entry.expiry != null) {
        data.expiry = this.entry.expiry;
      }

      this.adding = true;

      axios
        .put(
          `https://api.varsanpr.com/api/sites/${this.site.id}/barriers/${this.barrier_id}/whitelist`,
          {
            vehicles: [data],
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          this.$success("Entry added to whitelist");
          this.getWhitelist(this.pagination.page);
          this.entry = {
            registration: "",
            notes: "",
            expiry: null,
          };
        })
        .catch((err) => {
          if (err.response) {
            this.$error(err.response.data.message, err);
          } else {
            this.$error(err.message, err);
          }
        })
        .finally(() => {
          this.adding = false;
        });
    },
    deleteWhitelist(entry) {
      axios
        .delete(
          `https://api.varsanpr.com/api/sites/${this.site.id}/barriers/${this.barrier_id}/whitelist`,
          {
            headers: authHeader(),
            data: {
              entry_id: entry.id,
            },
          }
        )
        .then((res) => {
          this.$success("Entry deleted from whitelist");
          this.getWhitelist(this.pagination.page);
        })
        .catch((err) => {
          if (err.response) {
            this.$error(err.response.data.message, err);
          } else {
            this.$error(err.message, err);
          }
        });
    },
  },
  components: { Pagination },
};
</script>
