<template>
  <div v-if="!productSelected" class="p-v-sm">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th> {{$t('sites.name').toUpperCase()}} </th>
          <th> DATE/TIME CREATED </th>
          <th>  </th>
        </tr>
      </thead>
    
      <tbody>
        <tr v-show="list.length < 1">
          <td colspan="3" class="no-data-container">No data to show</td>
        </tr>
        <tr v-for="elem of list" :key="elem.index">
          <td class="cur_pointer" @click="showFoodTemp(elem)"> {{elem.name}} </td>
          <td class="cur_pointer" @click="showFoodTemp(elem)"> {{elem.datetime}} </td>
          <td> <button class="btn btn-sm btn-success" @click="activate(elem)">Activate</button> </td>
        </tr>
      </tbody>
    </table>
  </div>

  <food-temperature
    v-if="productSelected"
    :product="selectedProduct"
    @hideFoodTemperature="
      () => {
        productSelected = !productSelected;
      }
    "
  ></food-temperature>

</template>

<script>
import FoodTemperature from "./FoodTemperature.vue";

export default {
  name: 'InactiveDailyFood',
  props: {
    list: Array
  },
  components: {
    FoodTemperature,
  },
  data() {
    return {
      selectedProduct: {},
      productSelected: false,
    }
  },
  methods: {
    showFoodTemp(product) {
      this.selectedProduct = product;
      this.productSelected = true;
    },
    activate(food){
      this.$emit('food', food)
    }
  }
}
</script>