<template>
  <div class="row p-h-md p-v-md">
    <h4 class="sub-heading">{{ $t("sites.data.upload_signage") }}</h4>
    <!-- Add new signage image -->
    <div class="col-12">
      <div class="card">
        <div class="card-body row">
          <div class="col-4 m-r-md">
            <label for="filename" class="form-label">
              {{ $t("sites.site.file_name") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="filename"
              v-model="imageName"
              placeholder="Enter File Name"
            />
          </div>
          <div class="col-4">
            <div class="custom-file" style="margin-top: 30px">
              <input
                type="file"
                class="custom-file-input"
                @change="selectFile"
                accept="image/*"
              />
              <label class="custom-file-label"> {{ file_name }} </label>
            </div>
          </div>
          <div class="flex-center m-t-lg">
            <button
              class="btn btn-primary cursor-pointer"
              :class="{ disabled: file === null || imageName.length <= 1 }"
              @click="uploadImage"
            >
              {{ $t("sites.site.upload_image") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Uploaded Signage Images -->
    <h4 class="sub-heading" v-show="signage.length > 0">
      {{ $t("sites.data.uploaded_signage") }}
    </h4>
    <div class="card" v-for="elem in signage" :key="elem.id">
      <div class="card-body">
        <div class="bg-primary p-h-xs p-v-xs font-sm" id="site-img-name">
          {{ $t("sites.site.name") }} <span> : {{ elem.name }}</span>
        </div>
        <InstallPic :filename="elem.filename" :site_id="elem.site_id" />
      </div>
    </div>
  </div>
</template>

<script>
import SiteService from "../../../../services/site.service";
import { DateTime } from "luxon";
import InstallPic from "./InstallPic.vue";

export default {
  name: "Signage",
  props: {
    site: Object,
    client_id: Number,
  },
  components: { InstallPic },
  data() {
    return {
      signage: [],
      file: null,
      imageName: "",
    };
  },
  mounted() {
    SiteService.getSignage(this.$props.site.id)
      .then((response) => {
        response.data.signage.forEach((elem) => {
          elem.created_at = DateTime.fromSeconds(elem.created_at).toFormat(
            "dd:MM:yyyy HH:mm:ss"
          );
          elem.src = `${process.env.VUE_APP_API_URL}site/${elem.site_id}/signage/${elem.filename}`;
        });
        this.signage = response.data.signage;
      })
      .catch(() => {
        this.$error(this.$t("sites.data.failed_to_upload_signage"));
      });
  },
  methods: {
    selectFile(event) {
      this.file = event.target.files[0];
      this.imageName = this.file.name.split(".")[0];
    },
    uploadImage() {
      const data = new FormData();
      data.append("file", this.file, this.file.name);
      data.append("name", this.imageName);
      SiteService.uploadSignagePic(this.$props.site.id, data)
        .then((response) => {
          response.data.signage.forEach((elem) => {
            elem.created_at = DateTime.fromSeconds(elem.created_at).toFormat(
              "dd:MM:yyyy HH:mm:ss"
            );
            elem.src = `${process.env.VUE_APP_API_URL}site/${elem.site_id}/signage/${elem.filename}`;
          });
          this.signage = response.data.signage;
          this.$success(this.$t("sites.site.image_upload_success"));
          this.file = null;
          this.imageName = "";
        })
        .catch(() => {
          this.$error(this.$t("sites.site.image_upload_error"));
        });
    },
  },
  computed: {
    file_name: function () {
      if (this.file == null) {
        return "Select File";
      }
      if (this.file.name == null) {
        return "File Selected";
      }
      return this.file.name;
    },
  },
};
</script>

<style></style>
