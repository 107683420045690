<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">
      <!-- Tabs -->
      <div class="card widget widget-list">
        <ul class="nav nav-pills nav-justified">
          <li class="nav-item">
            <a
              href="javascript:void(0);"
              class="nav-link"
              aria-current="page"
              role="tab"
              :class="{ active: tabs.claims }"
              :aria-selected="tabs.claims"
              @click="selectTab('claims')"
            >
              {{ $t("sites.settings.claim_settings") }}
            </a>
          </li>

          <li class="nav-item">
            <a
              href="javascript:void(0);"
              class="nav-link"
              aria-current="page"
              role="tab"
              :class="{ active: tabs.barriers }"
              :aria-selected="tabs.barriers"
              @click="selectTab('barriers')"
            >
              {{ $t("sites.settings.barriers") }}
            </a>
          </li>

          <li class="nav-item">
            <a
              href="javascript:void(0);"
              class="nav-link"
              aria-current="page"
              role="tab"
              :class="{ active: tabs.mailingList }"
              :aria-selected="tabs.mailingList"
              @click="selectTab('mailingList')"
            >
              {{ $t("sites.settings.mailingList") }}
            </a>
          </li>
        </ul>
      </div>

      <h4 v-show="site !== null" class="section-heading p-v-xs">
        {{ site.name }}
      </h4>

      <div
        class="tab-pane fade"
        v-show="tabs.claims"
        :class="{ 'show active': tabs.claims }"
        role="tabpanel"
      >
        <Claims :site="site" :client_id="client_id" />
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.barriers"
        :class="{ 'show active': tabs.barriers }"
        role="tabpanel"
      >
        <Barriers :site="site" :client_id="client_id" />
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.mailingList"
        :class="{ 'show active': tabs.mailingList }"
        role="tabpanel"
      >
        <MailingList :site="site" :client_id="client_id" />
      </div>
    </div>
  </div>
</template>

<script>
import Claims from "./Claims.vue";
import Barriers from "./Barriers.vue";
import MailingList from "./MailingList.vue";

export default {
  name: "SiteSettings",
  props: {
    site: Object,
    client_id: Number,
  },
  components: { Claims, Barriers, MailingList },
  data() {
    return {
      tabs: {
        claims: true,
        barriers: false,
        mailingList: false,
      },
    };
  },
  methods: {
    selectTab(tab) {
      for (let elem in this.tabs) {
        this.tabs[elem] = false;
      }
      this.tabs[tab] = true;
    },
  },
};
</script>
