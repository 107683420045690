<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">
      <!-- Filter -->
      <div class="accordion" id="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filters"
              aria-expanded="false"
              aria-controls="filters"
            >
              {{ $t("events.filters.title") }} ({{
                $t("events.filters.click_to_open")
              }})
            </button>
          </h2>
          <div
            id="filters"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#filterAccordion"
          >
            <div class="accordion-body">
              <Form
                @submit="(values) => loadRedDiesel(pagination.page, values)"
                :validation-schema="schema"
                ref="form"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label for="startDateFilter" class="form-label">
                      {{ $t("sites.sign_ins.date_range") }}
                    </label>
                    <div class="input-group">
                      <Field
                        type="date"
                        name="startDate"
                        class="form-control"
                        id="startDateFilter"
                      />
                      <span class="input-group-text">to</span>
                      <Field
                        type="date"
                        name="endDate"
                        class="form-control"
                        id="endDateFilter"
                      />
                    </div>
                    <ErrorMessage name="startDate" class="error-feedback" />
                    <ErrorMessage name="endDate" class="error-feedback" />
                  </div>
                </div>

                <div class="row m-t-md">
                  <div class="col-12" style="width: auto">
                    <button
                      type="submit"
                      :disabled="loading"
                      class="btn btn-primary"
                      style="max-width: 150px"
                    >
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm m-r-xxs"
                      ></span>
                      <span>{{ $t("events.filters.search") }}</span>
                    </button>
                  </div>

                  <div class="col-12" style="width: auto">
                    <button
                      type="reset"
                      :disabled="loading"
                      class="btn btn-secondary"
                      style="max-width: 150px"
                      @click="(values) => clearFilter(pagination.page, values)"
                    >
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm m-r-xxs"
                      ></span>
                      <span>{{ $t("global.clear_filter") }}</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <!-- Table -->
      <h1 class="table-heading">
        {{ $t("sites.rdiesel_form") }}
        <!-- Exports -->
        <button
          v-if="redDieselList.length > 0"
          class="btn text-white float-right tx-uppercase tx-bold"
          @click="generateReport"
          style="background-color: #1d6f42"
        >
          Export Excel
        </button>
      </h1>

      <!-- loading -->
      <div v-show="loading" style="width: 100%; text-align: center">
        <div
          class="spinner-border"
          style="height: 4rem; width: 4rem"
          role="status"
        >
          <span class="visually-hidden">{{ $t("events.loading") }}...</span>
        </div>
      </div>

      <!-- Pagination -->
      <Pagination
        v-show="!loading"
        :config="pagination"
        @search="loadRedDiesel"
      />

      <!-- Red Diesel Sign Ins -->
      <table
        v-show="!loading"
        class="table table-striped m-t-lg m-b-lg"
        id="redDiesel"
      >
        <thead>
          <tr>
            <th scope="col">{{ $t("sites.name").toUpperCase() }}</th>
            <th scope="col">
              {{ $t("claims.new.vehicleRegistration").toUpperCase() }}
            </th>
            <th scope="col">{{ $t("global.reason").toUpperCase() }}</th>
            <th scope="col">{{ $t("global.category").toUpperCase() }}</th>
            <th scope="col">DATE/TIME COMPLETED</th>
          </tr>
        </thead>
        <tbody>
          <!-- If no data is present -->
          <tr v-show="redDieselList.length < 1">
            <td colspan="5" class="no-data-container">
              {{ $t("global.no_data") }}
            </td>
          </tr>

          <!-- Table Content -->
          <tr
            v-for="list in redDieselList"
            :key="'rdiesel-' + list.registration"
            style="vertical-align: middle"
          >
            <td>{{ list.name }}</td>
            <td>
              <span class="vrm bold number-plate-gbr">{{ list.plate }}</span>
            </td>
            <td>{{ list.reason }}</td>
            <td>{{ list.category }}</td>
            <td>{{ list.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Pagination from "../../../../components/Pagination.vue";
import siteService from "../../../../services/site.service";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";
import saveAs from "file-saver";

export default {
  name: "RedDiesel",
  props: {
    site: Object,
  },
  components: { Form, Field, ErrorMessage, Pagination },
  data() {
    const schema = yup.object().shape(
      {
        startDate: yup.date().when("endDate", {
          is: (val) => val !== null && val !== "" && val !== undefined,
          then: yup
            .date()
            .max(yup.ref("endDate"), "Start date must be before end date!")
            .required(
              "Start date is required when an end date has been supplied!"
            ),
          otherwise: yup.date().nullable(true),
        }),
        endDate: yup.date().when("startDate", {
          is: (val) => val !== null && val !== "" && val !== undefined,
          then: yup
            .date()
            .min(yup.ref("startDate"), "End date must be after start date!")
            .required(
              "End date is required when a start date has been supplied!"
            ),
          otherwise: yup.date().nullable(true),
        }),
      },
      ["startDate", "endDate"]
    );
    return {
      redDieselList: [],
      loading: false,
      schema,
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0,
      },
      sites: [],
      selectedSite: -1,
    };
  },
  mounted() {
    this.loadRedDiesel();
  },
  methods: {
    async loadRedDiesel(page = 1, params = {}) {
      this.loading = true;
      siteService
        .getRedDiesel(this.site.id, page, params)
        .then((response) => {
          response.data.submissions.forEach((entry) => {
            entry.created_at = DateTime.fromSeconds(entry.created_at).toFormat(
              "dd/MM/yyyy HH:mm:ss"
            );
          });
          this.redDieselList = response.data.submissions;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          this.$error("Failed loading submissions", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateReport() {
      console.log("Exporting Excel");
      let data = [];
      for (let entry of this.redDieselList) {
        data.push({
          Name: entry.name,
          "Vehicle Registration": entry.plate,
          Reason: entry.reason,
          Category: entry.category,
          Datetime: entry.created_at,
        });
      }
      let book = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(book, ws, "Red Diesel Report");
      var wopts = { bookType: "xlsx", bookSST: false, type: "array" };
      var wbout = XLSX.write(book, wopts);
      /* the saveAs call downloads a file on the local machine */
      saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        `RedDieselReport.xlsx`
      );
    },
  },
};
</script>

<style></style>
