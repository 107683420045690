<template>
  <div class="row">
    <div style="padding: 20px 12px 0">
      <span class="site-name"> {{ name }} </span>
      <button
        class="btn btn-primary"
        style="float: right"
        :class="{ 'display-none': tabs.inactive }"
        @click="() => addFridgeModal.show()"
      >
        {{ $t("sites.temperature.add_fridge") }}
      </button>
    </div>

    <ul class="nav nav-tabs m-t-sm" role="tablist" style="padding: 0 12px">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.active }"
          role="tab"
          :aria-selected="tabs.active"
          href="javascript:void(0);"
          @click="selectTab('active')"
        >
          Active ({{ fridges.active.length }})
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.inactive }"
          role="tab"
          :aria-selected="tabs.inactive"
          href="javascript:void(0);"
          @click="selectTab('inactive')"
        >
          Inactive ({{ fridges.inactive.length }})
        </button>
      </li>
    </ul>

    <div
      class="tab-pane fade"
      v-show="tabs.active"
      :class="{ 'show active': tabs.active }"
      role="tabpanel"
    >
      <active-fridges
        :list="fridges.active"
        @fridge="deactivate"
        :name="name"
      ></active-fridges>
    </div>

    <div
      class="tab-pane fade"
      v-show="tabs.inactive"
      :class="{ 'show active': tabs.inactive }"
      role="tabpanel"
    >
      <inactive-fridges
        :list="fridges.inactive"
        @fridge="activate"
        :name="name"
      ></inactive-fridges>
    </div>
  </div>

  <div
    class="modal fade"
    id="addFridgeModal"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 class="m-r-sm textAlign-center flex">
            <span class="widget-list-item-icon">
              <i
                class="material-icons-outlined"
                style="vertical-align: middle; padding: 10px"
              >
                kitchen</i
              >
            </span>
            {{ $t("sites.temperature.add_fridge").toUpperCase() }}
          </h4>
          <div class="form-group p-v-sm" style="width: 100%">
            <label class="form-label"> Fridge Name </label>
            <input
              type="text"
              v-model="fridgeName"
              class="form-control"
              placeholder="Fridge Name"
            />
            <span class="text-danger" v-show="addFridgeError">
              Fridge name already exits
            </span>
          </div>
          <div class="flex-center p-v-sm">
            <button
              class="btn btn-secondary m-r-sm"
              @click="() => addFridgeModal.hide()"
            >
              {{ $t("global.close") }}
            </button>
            <button class="btn btn-primary" @click="addFridge">
              <span
                v-show="addingFridge"
                class="spinner-border spinner-border-sm m-r-xxs"
              ></span>
              <span>
                <i class="material-icons"> add </i> {{ $t("global.add") }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import temperatureService from "../../../../../services/temperature.service";
import { DateTime } from "luxon";
import ActiveFridges from "./fridges/activefridges/ActiveFridges.vue";
import InactiveFridges from "./fridges/inactivefridges/InactiveFridges.vue";

export default {
  name: "Fridge",
  props: {
    site_id: Number | String,
    name: String,
  },
  components: {
    ActiveFridges,
    InactiveFridges,
  },
  data() {
    return {
      loading: true,
      fridges: {
        active: [],
        inactive: [],
      },
      tabs: {
        active: true,
        inactive: false,
      },
      addFridgeModal: null,
      fridgeName: "",
      addingFridge: false,
      addFridgeError: false,
    };
  },
  mounted() {
    this.getFridges();
    this.addFridgeModal = new bootstrap.Modal(
      document.getElementById("addFridgeModal")
    );
  },
  methods: {
    getFridges() {
      this.loading = true;
      temperatureService
        .getFridges(this.site_id)
        .then((response) => {
          response.data.data.forEach((fridge) => {
            fridge.datetime = DateTime.fromSeconds(fridge.datetime).toFormat(
              "dd LLL yyyy HH:mm:ss"
            );
            if (fridge.status === 1) {
              this.fridges.active.push(fridge);
            } else {
              this.fridges.inactive.push(fridge);
            }
          });
          this.loading = false;
        })
        .catch((error) => {
          this.$error(this.$t("sites.temperature.fridge_error"), error);
          this.loading = false;
        });
    },
    addFridge() {
      this.addingFridge = true;
      this.addFridgeError = false;
      temperatureService
        .addNewFridge(this.site_id, this.fridgeName)
        .then((response) => {
          this.$success(this.$t("sites.temperature.add_fridge_success"));
          this.addingFridge = false;
          this.fridges.active.unshift({
            name: response.data.fridge.name,
            datetime: DateTime.fromFormat(
              response.data.fridge.datetime.replace("Z", "").replace("T", " "),
              "yyyy-MM-dd HH:mm:ss.SSS"
            ).toFormat("dd LLL yyyy HH:mm:ss"),
          });
          this.addFridgeModal.hide();
        })
        .catch((error) => {
          //TODO - check if the error is fridge name exits
          console.log(error);
          this.addFridgeError = true;
          this.addingFridge = false;
        });
    },
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    activate(fridge) {
      console.log("activate", fridge);
      temperatureService
        .activateFridge(this.site_id, fridge.id)
        .then((response) => {
          this.$success("Activated fridge successfully");
          this.fridges.active.unshift(fridge);
          this.fridges.inactive = this.fridges.inactive.filter(
            (f) => f.id !== fridge.id
          );
        })
        .catch((error) => {
          this.$error("Failed to deactivate fridge", error);
        });
    },
    deactivate(fridge) {
      console.log("deactivate", fridge);
      temperatureService
        .deactivateFridge(this.site_id, fridge.id)
        .then((response) => {
          this.$success("Deactivated fridge successfully");
          this.fridges.inactive.unshift(fridge);
          this.fridges.active = this.fridges.active.filter(
            (f) => f.id !== fridge.id
          );
        })
        .catch((error) => {
          this.$error("Failed to deactivate fridge", error);
        });
    },
  },
};
</script>

<style scoped>
.nav-tabs {
  border-bottom: none;
}

.table-heading {
  margin-bottom: 0;
  margin-top: 40px;
  padding-top: 0px;
}

.widget-list-item-icon {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  margin-right: 10px;
  vertical-align: middle;
  float: left;
  color: #fd7e14;
  background: rgba(255, 149, 0, 0.25) !important;
}

.flex {
  align-items: center;
}

.display-none {
  display: none;
}

.site-name {
  font-size: 25px;
}

.m-r-sm.textAlign-center.flex {
  width: fit-content;
  margin: auto;
}

.text-danger {
  font-weight: 400;
}
</style>
