<template>
  <img
    :id="id"
    v-if="src !== null"
    :src="src"
    @click="$emit('click')"
    @load="$emit('load')"
  />
</template>

<script>
import axios from "axios";
import authHeader from "../../../../services/auth-header";
export default {
  name: "BrandImage",
  props: {
    filename: String,
    id: String,
  },
  data() {
    return {
      src: null,
    };
  },
  mounted() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      axios
        .get(`https://api.varsanpr.com/api/sites/brands/${this.filename}`, {
          responseType: "arraybuffer",
          headers: authHeader(),
        })
        .then((response) => {
          this.src = `data:image/jpeg;base64,${Buffer.from(
            response.data,
            "binary"
          ).toString("base64")}`;
        })
        .catch((error) => {
          this.$error(`Failed to download image ${this.filename}`, error);
          return null;
        });
    },
  },
  watch: {
    filename: function (val) {
      this.loadImage();
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
  /* width: auto; */
}
</style>
