<template>
    <div class="row">
      <ul class="nav nav-tabs m-t-sm" role="tablist" style="padding: 0 12px">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ 'show active': tabs.active }"
            role="tab"
            :aria-selected="tabs.active"
            href="javascript:void(0);"
            @click="selectTab('active')"
          >
            Active ({{ foodList.active.length }})
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            :class="{ 'show active': tabs.inactive }"
            role="tab"
            :aria-selected="tabs.inactive"
            href="javascript:void(0);"
            @click="selectTab('inactive')"
          >
            Inactive ({{ foodList.inactive.length }})
          </button>
        </li>
      </ul>
    </div>

    <div
      class="tab-pane fade"
      v-show="tabs.active"
      :class="{ 'show active': tabs.active }"
      role="tabpanel"
    >
      <active-hot-food
        :list="foodList.active"
        @food="deactivate"
        :name="name"
      ></active-hot-food>
    </div>
    <div
      class="tab-pane fade"
      v-show="tabs.inactive"
      :class="{ 'show active': tabs.inactive }"
      role="tabpanel"
    >
      <inactive-hot-food
        :list="foodList.inactive"
        @food="activate"
        :name="name"
      ></inactive-hot-food>
    </div>

</template>

<script>
import temperatureService from '../../../../../../../services/temperature.service';
import ActiveHotFood from './ActiveHotFood.vue';
import InactiveHotFood from './InactiveHotFood.vue';
import { DateTime } from 'luxon';

export default {
  name: 'HotFood',
  props: {
    site_id: Number,
    name: String
  },
  components: {
    ActiveHotFood,
    InactiveHotFood,
  },
  data() {
    return {
      loading: true,
      foodList: {
        active: [],
        inactive: []
      },
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0
      },
      tabs: {
        active: true,
        inactive: false,
      },
      foodType: 'hot-food',
    } 
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    getProductList(page = 1) {
      this.loading = true;
      let active = [];
      let inactive = [];
      temperatureService
        .getHotProducts(this.site_id, this.foodType, page)
        .then(response => {
          response.data.data.forEach((item) => {
            item.datetime = DateTime.fromSeconds(item.datetime).toFormat(
              'dd LLL yyyy HH:mm:ss'
            );
            if (item.status === 1) {
              active.push(item);
            } else {
              inactive.push(item);
            }
          });
          this.foodList.active = active;
          this.foodList.inactive = inactive;
          this.pagination = response.data.pagination;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        }) 
    },
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    activate(product) {
      console.log("activate", product);
      temperatureService
        .activateDailyFood(this.site_id, product.id)
        .then((response) => {
          this.$success("Activated product successfully");
          this.foodList.active.unshift(product);
          this.foodList.inactive = this.foodList.inactive.filter(
            (f) => f.id !== product.id
          );
        })
        .catch((error) => {
          this.$error("Failed to activate product", error);
        });
    },
    deactivate(product) {
      console.log("deactivate", product);
      temperatureService
        .deactivateDailyFood(this.site_id, product.id)
        .then((response) => {
          this.$success("Deactivated product successfully");
          this.foodList.inactive.unshift(product);
          this.foodList.active = this.foodList.active.filter(
            (f) => f.id !== product.id
          );
        })
        .catch((error) => {
          this.$error("Failed to deactivate product", error);
        });
    },
  }
}
</script>