<template>
  <div
    v-if="loading"
    style="width: 100%; text-align: center; margin-top: calc(50%)"
  >
    <div class="spinner-border" style="height: 4rem; width: 4rem" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!loading && closeCameraCard">
    <h4 class="section-heading m-b-md">
      {{ site.name }} ({{ siteCameras.length }})
    </h4>
    <div class="row m-t-sm">
      <div
        class="card col-12"
        v-for="camera in cameras"
        :key="'camera-' + camera.id"
        @click="selectCamera(camera)"
      >
        <div class="card-header">
          <span class="card-title"> {{ camera.name }} </span>
          <span class="float-end">
            <div
              class="status-container"
              :class="'Online' ? 'bg-success b-success' : 'bg-danger b-danger'"
            >
              {{ camera.status }}
            </div></span
          >
        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item cameras">
              <i
                v-if="camera.model !== null"
                class="material-icons text-success align-middle m-r-xs"
              >
                done
              </i>
              <i
                v-else
                class="material-icons text-danger align-middle m-r-sm"
              ></i>
              <span class="flex-fill"> {{ $t("global.model") }} </span>
              <strong class="float-end"> {{ camera.model }}</strong>
            </li>

            <li class="list-group-item cameras">
              <i
                v-if="camera.mode !== null"
                class="material-icons text-success align-middle m-r-xs"
              >
                done
              </i>
              <i
                v-else
                class="material-icons text-danger align-middle m-r-sm"
              ></i>
              <span class="flex-fill"> {{ $t("global.mode") }} </span>
              <strong class="float-end">
                {{
                  camera.mode.charAt(0).toUpperCase() + camera.mode.slice(1)
                }}</strong
              >
            </li>

            <li class="list-group-item cameras">
              <i
                v-if="camera.ip !== null"
                class="material-icons text-success align-middle m-r-xs"
              >
                done
              </i>
              <i
                v-else
                class="material-icons text-danger align-middle m-r-sm"
              ></i>
              <span class="flex-fill"> IP </span>
              <strong class="float-end"> {{ camera.ip }}</strong>
            </li>

            <li class="list-group-item cameras">
              <i
                v-if="camera.mac !== null"
                class="material-icons text-success align-middle m-r-xs"
              >
                done
              </i>
              <i
                v-else
                class="material-icons text-danger align-middle m-r-sm"
              ></i>
              <span class="flex-fill"> MAC </span>
              <strong class="float-end"> {{ camera.mac }}</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-show="showCamera">
    <h4 class="section-heading m-b-md">{{ selectedCamera?.name }}</h4>
    <camera :cameraObj="selectedCamera" @closeCamera="closeCameraCard" />
  </div>
</template>

<script>
import CameraService from "../../../../../services/camera.service";
import Camera from "./Camera.vue";

export default {
  name: "CamerasNew",
  props: {
    site: Object,
    client_id: String | Number,
    siteCameras: Array,
  },
  components: { Camera },
  data() {
    return {
      loading: true,
      cameras: [],
      selectedCamera: null,
      showCamera: false,
    };
  },
  mounted() {},
  methods: {
    async getCameraDetails() {
      this.loading = true;
      for await (let camera of this.siteCameras) {
        CameraService.getCamera(camera.id)
          .then((response) => {
            this.cameras.push(response.data.camera);
          })
          .catch((error) => {
            this.$error($t("sites.devices.error.camera_error"), error);
          });
      }
      this.loading = false;
      // console.log(this.cameras);
    },
    selectCamera(camera) {
      this.selectedCamera = camera;
      this.showCamera = true;
      // /this.$router.push({name:'camera', params: {camera: this.selectCamera}});
    },
    closeCameraCard(value) {
      this.showCamera = false;
    },
  },
  watch: {
    siteCameras: function () {
      this.getCameraDetails();
    },
  },
};
</script>

<style scoped>
/* .list-group-item {
        color: white;
    } */

.card:hover {
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

/* media queries */
@media (min-width: 2560px) {
  .row {
    margin: 0;
    justify-content: space-between;
  }

  .col-12 {
    width: 48%;
  }
}
</style>
