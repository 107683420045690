<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">
      <ul class="nav nav-pills nav-justified" id="temperature">
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0);"
            :class="{ active: tabs.forecourt }"
            role="tab"
            :aria-selected="tabs.forecourt"
            @click="selectTab('forecourt')"
          >
            Forecourt
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            href="javascript:void(0);"
            :class="{ active: tabs.manager }"
            role="tab"
            :aria-selected="tabs.manager"
            @click="selectTab('manager')"
          >
            Manager
          </a>
        </li>
      </ul>

      <div
        class="tab-pane fade"
        v-show="tabs.forecourt"
        :class="{ 'show active': tabs.forecourt }"
        role="tabpanel"
      >
        <ForecourtChecklist
          :site="site"
          :client_id="client_id"
        ></ForecourtChecklist>
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.manager"
        :class="{ 'show active': tabs.manager }"
        role="tabpanel"
      >
        <ManagerChecklist
          :site="site"
          :client_id="client_id"
        ></ManagerChecklist>
      </div>
    </div>
  </div>
</template>

<script>
import ForecourtChecklist from "./ForecourtChecklist.vue";
import ManagerChecklist from "./ManagerChecklist.vue";

export default {
  name: "Checklist",
  props: {
    site: Object,
    client_id: Number | String,
  },
  components: {
    ForecourtChecklist,
    ManagerChecklist,
  },
  data() {
    return {
      tabs: {
        forecourt: true,
        manager: false,
      },
    };
  },
  mounted() {},
  methods: {
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
  },
  components: { ForecourtChecklist, ManagerChecklist },
};
</script>
