<template>

  <div class="row">
    <ul class="nav nav-tabs m-t-sm" role="tablist" style="padding: 0 12px">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.active }"
          role="tab"
          :aria-selected="tabs.active"
          href="javascript:void(0);"
          @click="selectTab('active')"
        >
          Active ({{ foodList.active.length }})
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ 'show active': tabs.inactive }"
          role="tab"
          :aria-selected="tabs.inactive"
          href="javascript:void(0);"
          @click="selectTab('inactive')"
        >
          Inactive ({{ foodList.inactive.length }})
        </button>
      </li>
    </ul>

    <div
      class="tab-pane fade"
      v-show="tabs.active"
      :class="{ 'show active': tabs.active }"
      role="tabpanel"
    >
      <active-thaw-serve
        :list="foodList.active"
        @food="deactivate"
        :name="name"
      ></active-thaw-serve>
    </div>
    <div
      class="tab-pane fade"
      v-show="tabs.inactive"
      :class="{ 'show active': tabs.inactive }"
      role="tabpanel"
    >
      <inactive-thaw-serve
        :list="foodList.inactive"
        @food="activate"
        :name="name"
      ></inactive-thaw-serve>
    </div>

  </div>

</template>

<script>
import temperatureService from "../../../../../../../services/temperature.service";
import ActiveThawServe from "./ActiveThawServe.vue";
import InactiveThawServe from "./InactiveThawServe.vue";
import Pagination from "../../../../../../../components/Pagination.vue";
import { DateTime } from "luxon";

export default {
  name: "ThawServe",
  props: {
    site_id: Number,
    name: String,
  },
  components: {
    ActiveThawServe,
    InactiveThawServe,
    Pagination,
  },
  data() {
    return {
      loading: true,
      foodList: {
        active: [],
        inactive: [],
      },
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0,
      },
      tabs: {
        active: true,
        inactive: false,
      },
      foodType: "thaw-serve",
    };
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      this.loading = true;
      let active = [];
      let inactive = [];
      temperatureService
        .getThawServe(this.site_id)
        .then((response) => {
          response.data.data.forEach((item) => {
            item.datetime = DateTime.fromSeconds(item.datetime).toFormat(
              "dd LLL yyyy HH:mm:ss"
            );
            if (item.status === 1) {
              active.push(item);
            } else {
              inactive.push(item);
            }
          });
          this.foodList.active = active;
          this.foodList.inactive = inactive;
          this.pagination = response.data.pagination;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    activate(product) {
      console.log("activate", product);
      temperatureService
        .activateDailyFood(this.site_id, product.id)
        .then((response) => {
          this.$success("Activated product successfully");
          this.foodList.active.unshift(product);
          this.foodList.inactive = this.foodList.inactive.filter(
            (f) => f.id !== product.id
          );
        })
        .catch((error) => {
          this.$error("Failed to activate product", error);
        });
    },
    deactivate(product) {
      console.log("deactivate", product);
      temperatureService
        .deactivateDailyFood(this.site_id, product.id)
        .then((response) => {
          this.$success("Deactivated product successfully");
          this.foodList.inactive.unshift(product);
          this.foodList.active = this.foodList.active.filter(
            (f) => f.id !== product.id
          );
        })
        .catch((error) => {
          this.$error("Failed to deactivate product", error);
        });
    },
  },
};
</script>

<style></style>
