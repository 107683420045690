<template>
  <div>
    <div v-if="!fridgeSelected" class="p-v-sm">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th>{{ $t("sites.name").toUpperCase() }}</th>
            <th>Serial Number</th>
            <th>DATE/TIME CREATED</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="list.length < 1">
            <td colspan="3" class="no-data-container">
              {{ $t("global.no_data") }}
            </td>
          </tr>
          <tr v-for="elem of list" :key="elem.index">
            <td class="cur_pointer" @click="showFridgeTemp(elem)">
              {{ elem.name }}
            </td>
            <td>{{ elem.serial_number }}</td>
            <td class="cur_pointer" @click="showFridgeTemp(elem)">
              {{ elem.datetime }}
            </td>
            <td>
              <button class="btn btn-sm btn-danger" @click="deactivate(elem)">
                Deactivate
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <active-device-temperature
      v-if="fridgeSelected"
      :fridge="selectedFridge"
      @hideFridgeTemperature="
        () => {
          fridgeSelected = !fridgeSelected;
        }
      "
    ></active-device-temperature>
  </div>
</template>

<script>
import ActiveDeviceTemperature from "./ActiveDeviceTemperature.vue";

export default {
  name: "ActiveDevices",
  props: {
    list: Array,
  },
  components: {
    ActiveDeviceTemperature,
  },
  data() {
    return {
      selectedFridge: {},
      fridgeSelected: false,
    };
  },
  methods: {
    showFridgeTemp(fridge) {
      this.selectedFridge = fridge;
      this.fridgeSelected = true;
    },
    deactivate(fridge) {
      this.$emit("fridge", fridge);
    },
  },
};
</script>

<style></style>
