<template>
  <div class="row">
    <div class="col-9" style="margin-left: 280px">
      <div class="card widget widget-list">
        <ul class="nav nav-pills nav-justified">
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              :class="{ active: tabs.cameras }"
              role="tab"
              :aria-selected="tabs.cameras"
              @click="selectTab('cameras')"
              >Cameras</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              :class="{ active: tabs.displays }"
              role="tab"
              :aria-selected="tabs.displays"
              @click="selectTab('displays')"
              >Displays</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              :class="{ active: tabs.tablets }"
              role="tab"
              :aria-selected="tabs.tablets"
              @click="selectTab('tablets')"
              >Tablets</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="javascript:void(0);"
              :class="{ active: tabs.switches }"
              role="tab"
              :aria-selected="tabs.switches"
              @click="selectTab('switches')"
              >Switches</a
            >
          </li>
        </ul>

        <!--        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">-->
        <!--          <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>-->
        <!--        </div>-->
        <!--        <div class="ps__rail-y" style="top: 0px; right: 0px;">-->
        <!--          <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>-->
        <!--        </div>-->
      </div>

      <div
        class="tab-pane fade"
        v-show="tabs.cameras"
        :class="{ 'show active': tabs.cameras }"
        role="tabpanel"
      >
        <!-- <cameras v-if="$can('cameras-view')" :site="site" :client_id="client_id" :cameras="cameras"/> -->
        <cameras-new
          v-if="$can('cameras-view')"
          :site="site"
          :client_id="client_id"
          :siteCameras="siteCameras"
        />
        <!-- <div v-else> You do not have access to view site cameras </div> -->
      </div>
      <div
        class="tab-pane fade"
        v-show="tabs.displays"
        :class="{ 'show active': tabs.displays }"
        role="tabpanel"
      >
        <displays :site="site" :client_id="client_id" :displays="displays" />
      </div>
      <div
        class="tab-pane fade"
        v-show="tabs.tablets"
        :class="{ 'show active': tabs.tablets }"
        role="tabpanel"
      >
        <tablets
          :site="site"
          :client_id="client_id"
          :siteTablets="siteTablets"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Cameras from "./cameras/Cameras";
import CamerasNew from "./cameras/CamerasNew.vue";
import Displays from "./displays/Displays.vue";
import Tablets from "./tablets/Tablets.vue";

export default {
  name: "SiteDevices",
  components: {
    Cameras,
    Displays,
    Tablets,
    CamerasNew,
  },
  props: {
    // id: Number
    site: Object,
    client_id: Number,
    siteCameras: Array,
    displays: Array,
    siteTablets: Array,
  },
  data() {
    return {
      tabs: {
        cameras: true,
        displays: false,
        tablets: false,
      },
    };
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      e = e || window.event;
      if (e.ctrlKey) {
        switch (e.key) {
          case "ArrowRight":
          case "ArrowDown":
            this.nextTab();
            break;
          case "ArrowLeft":
          case "ArrowUp":
            this.previousTab();
            break;
        }
      }
    });
  },
  methods: {
    selectTab(tab) {
      for (let t in this.tabs) {
        this.tabs[t] = false;
      }
      this.tabs[tab] = true;
    },
    nextTab() {
      let tabNames = Object.keys(this.tabs);
      let currentTab = null;

      Object.keys(this.tabs).forEach((t) => {
        if (this.tabs[t] === true) {
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      if (index + 1 > tabNames.length - 1) {
        index = 0;
      } else {
        index++;
      }

      this.selectTab(tabNames[index]);
    },
    previousTab() {
      let tabNames = Object.keys(this.tabs);
      console.log(tabNames);
      let currentTab = null;

      Object.keys(this.tabs).forEach((t) => {
        if (this.tabs[t] === true) {
          currentTab = t;
        }
      });

      let index = tabNames.indexOf(currentTab);
      console.log("Original Index:", index);
      if (index - 1 < 0) {
        index = tabNames.length - 1;
      } else {
        index--;
      }

      this.selectTab(tabNames[index]);
    },
  },
};
</script>
