<template>
  <!-- <div class="row">
    <div class="col-12">

      <div class="card widget widget-list">

        <ul class="nav nav-pills nav-justified">
          <li v-for="t in tabs" class="nav-item">
            <a class="nav-link" href="javascript:void(0);" :class="{'active': t.showing}" role="tab"
               @click="selectTab(t.name)">{{ t.name }}</a></li>
        </ul>

      </div>

      <div class="card-body">
        <create :site="site" v-show="selectedTab.name === 'Create Camera'" :client_id="client_id"/>
        <camera v-for="tab in realTabs" v-show="tab.showing" :cam="tab" :key="tab.id"/>
      </div>

    </div>
  </div> -->
  <!-- Table heading -->
  <div v-show="!loading" class="flex-center space-between">
    <h4 class="section-heading">{{ site.name }} ({{ cameraList.length }})</h4>
    <button class="btn btn-primary" @click="$router.push()">
      <i class="material-icons"> add </i> Add New Camera
    </button>
  </div>

  <div v-show="!loading" class="flex-center m-t-md space-between">
    <!-- Camera Installation Map -->
    <!-- Image needs to come in dynamically -->
    <!-- <div class="card col-3" style="padding: 0px;">
      <img 
        src="https://media.wired.com/photos/59269cd37034dc5f91bec0f1/191:100/w_1280,c_limit/GoogleMapTA.jpg" 
        class="m-t-xs"
        style="border-radius: 10px 10px 0 0; width: 100%"
      >

      <div class="m-t-sm m-b-sm">
        <span class="card-s-title"> Camera Installation Map </span>
      </div>

    </div> -->

    <!-- Cameras Installed -->
    <!-- @click="$router.push({name: 'camera', params: {site: site, client_id: client_id}})" -->
    <div
      v-for="elem in cameras"
      :key="'camera-' + elem.id"
      class="card"
      @click="selectCamera(elem)"
    >
      <!-- Camera Installation Image -->
      <!-- <AssetImage /> -->
      <div class="card-body">
        <div
          class="m-t-sm"
          style="
            border-radius: 10px 10px 0 0;
            width: 100%;
            border: 2px solid white;
            height: 300px;
          "
        ></div>
        <div class="flex-center space-between m-t-xs">
          <div class="col-6">
            <span class="camera-name"> {{ elem.name }} </span>
          </div>
          <div class="col-6">
            <span
              class="status-container"
              :class="
                elem.status === 'Online'
                  ? 'bg-success b-success'
                  : 'bg-danger b-danger'
              "
              style="float: right"
            >
              {{ elem.status.toUpperCase() }}
            </span>
          </div>
        </div>
        <div class="font-xs">{{ $t("global.model") }} - {{ elem.model }}</div>
        <div class="font-xs m-b-xs">
          {{ $t("sites.devices.camera_mode") }} -
          {{ elem.mode.charAt(0).toUpperCase() + elem.mode.slice(1) }}
        </div>
      </div>
    </div>
  </div>

  <div>
    <camera v-if="selectedCamera" :camera="selectedCamera" />
  </div>
</template>

<script>
// import Create from "./Create";
// import Camera from "./Camera";
// import CameraService from "../../../../../services/camera.service";

// export default {
//   name: 'Cameras',
//   components: {
//     Create,
//     Camera
//   },
//   props: {
//     id: Number
//   },
//   data() {
//     return {
//       cameras: [],
//       tabs: [
//         {
//           name: 'Create Camera',
//           id: -1,
//           camera: false,
//           showing: true
//         }
//       ]
//     }
//   },
//   mounted() {
//     this.loadCameras();
//     if (this.tabs.length > 0) {
//       this.tabs[0].showing = true;
//     }
//   },
//   methods: {
//     loadCameras(site = this.$store.state.auth.user.selectedClient) {
//       CameraService.getCameras(site).then(response => {
//         this.cameras = response.data.cameras;
//         this.cameras.forEach((camera) => {
//           this.tabs.push({
//             name: camera.name,
//             id: camera.id,
//             camera: true,
//             showing: false
//           });
//         });
//       });
//     },
//     selectTab(t) {
//       let findTab = this.tabs.filter(tab => {
//         return tab.name === t;
//       })[0];

//       if (!findTab) return;

//       this.tabs.forEach(tab => {
//         tab.showing = false;
//       });

//       findTab.showing = true;

//     }
//   },
//   computed: {
//     selectedTab: function () {
//       return this.tabs.filter(t => {
//         return t.showing;
//       })[0];
//     },
//     realTabs: function () {
//       return this.tabs.filter((t) => {
//         return t.camera;
//       });
//     }
//   }
// }
import CameraService from "../../../../../services/camera.service";
import AssetImage from "../../../../../components/AssetImage.vue";
import Camera from "./Camera";

export default {
  name: "Cameras",
  props: {
    site: Object,
    client_id: String,
  },
  components: { AssetImage, Camera },
  data() {
    return {
      cameraList: [],
      loadingList: true,
      cameras: [],
      selectedCamera: null,
    };
  },
  mounted() {
    this.getCameraList();
  },
  methods: {
    async getCameraList() {
      this.loadingList = true;
      const response = await CameraService.getCameras(this.$route.params.id);
      this.cameraList = response.data.cameras;
      this.loadingList = false;
      console.log(this.cameraList);
      this.getCameras();
    },
    getCameras() {
      for (let camera of this.cameraList) {
        CameraService.getCamera(camera.id)
          .then((response) => {
            this.cameras.push(response.data.camera);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    selectCamera(camera) {
      this.selectedCamera = camera;
      console.log(this.selectedCamera);
    },
  },
};
</script>

<style scoped>
.status-container {
  border-radius: 50px;
  padding: 5px 10px;
  font-weight: 600;
}

.card {
  width: 400px;
  height: auto;
  padding: 0px;
  cursor: pointer;
}

.card:hover {
  box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
}

.card-body {
  padding: 0px 15px;
}

.camera-name {
  font-size: 18px;
  font-weight: 500;
}
</style>
